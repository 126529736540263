<template>
  <Combobox as="div"
            class="relative"
            v-slot="{ open }"
            :value="value"
            @update:model-value="changeSelected"
            :default-value="value"
            :key="value.key"
  >
    <ComboboxButton class="flex items-center w-full px-6 py-2 overflow-hidden text-gray-200 transition bg-gray-300 rounded-full hover:text-gray-100"
                    @click="query = ''"
    >
      <div class="flex-shrink min-w-0 p-0 my-2 mr-2 overflow-hidden text-left bg-gray-300 border-none lg:mr-1 lg:h-6 ring-0 focus:ring-0 line-clamp-1">
        {{ value.label }}
      </div>

      <IconChevronDown class="flex-shrink-0 w-6 h-6 ml-auto transition fill-current"
                       :class="open ? '-scale-y-100' : 'scale-y-100'"
      />
    </ComboboxButton>
    <TransitionRoot leave="transition ease-in duration-100"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
    >
      <ComboboxOptions class="absolute z-20 w-full mt-2 overflow-hidden text-gray-200 bg-gray-300 top-14 rounded-xl">
        <div class="py-2 overflow-auto max-h-96"
             data-lenis-prevent
        >
          <ComboboxOption v-for="item in filteredItems"
                          :key="item.key"
                          :value="item"
                          class="px-6 py-2 transition cursor-default hover:bg-gray-250 hover:text-gray-100"
                          :class="value.key === item.key ? 'bg-gray-250' : ''"
          >
            {{ item.label }}
          </ComboboxOption>
        </div>
      </ComboboxOptions>
    </TransitionRoot>
  </Combobox>
</template>
<script setup lang="ts">
import { ref, computed } from 'vue';
import { Combobox, ComboboxOptions, ComboboxOption, ComboboxButton, TransitionRoot } from '@headlessui/vue';
import type { ComboBoxItemType } from '../types/combobox';

interface dropdownProps {
  items: ComboBoxItemType[];
  value: ComboBoxItemType;
}

const props = defineProps<dropdownProps>();
const emit = defineEmits(['change']);
const query = ref('');

const changeSelected = (item: ComboBoxItemType) => {
  emit('change', item);
};

const filteredItems = computed(() =>
  query.value === ''
    ? props.items
    : props.items.filter(item => {
      return item.label.toLowerCase().includes(query.value.toLowerCase());
    })
);
</script>
